import { EventType } from "../commonTypes";
import { DateRange } from "./datetime";

type OriginalMessage = {
  id: string;
  timestamp: string;
  sender: string;
  message: string;
  is_agent: boolean;
  format: "voice" | "text";
  interaction_id: string;
};

export type TransformedMessage = {
  message: string;
  from: string;
  message_id: string;
  interaction_id: string;
  format: "voice" | "text";
};

export type UserToken = {
  sub: string;
  name: string;
  email: string;
  email_verified: boolean;
  metadata: any | null;
  is_admin: boolean;
};

export type OriginalFormat = {
  messages: OriginalMessage[];
};

export type TransformedFormat = {
  messages: TransformedMessage[];
};

export const hourMinuteFormatter = new Intl.DateTimeFormat("en-US", {
  hour: "2-digit",
  minute: "2-digit",
});

export const dateFormatter = new Intl.DateTimeFormat("en-US", {
  dateStyle: "long",
});

export const timeFormatter = new Intl.DateTimeFormat("en-US", {
  hour12: false,
  hour: "2-digit",
  minute: "2-digit",
});

export function formatTimeRange(_range: DateRange) {
  return timeFormatter.format(_range.start) + " - " + timeFormatter.format(_range.end);
}

export type AvailableHourListProps = {
  userName: string;
  ranges: DateRange[];
  eventType: EventType;
  onSuccess: (_eventType: EventType, _range: DateRange) => void;
};

export const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

export type MapFunction<K, V, NK, NV> = ([_key, _value]: [K, V]) => [NK, NV];

export function mapEntries<K extends string | number, V, NK extends string | number, NV>(
  obj: Record<K, V>,
  mapFn: MapFunction<K, V, NK, NV>,
): Record<NK, NV> {
  // The accumulator in reduce() is explicitly typed as Record<NK, NV>
  return Object.entries(obj).reduce<Record<NK, NV>>(
    (acc, entry) => {
      // entry is implicitly [string, V] since Object.entries returns an array of string-keyed entries
      // We cast the key part to K to match the original key type
      const [key, value] = entry as [K, V];
      // mapFn is applied to the tuple [key, value], resulting in a new tuple [newKey, newValue]
      const [newKey, newValue] = mapFn([key, value]);
      // The new key-value pair is added to the accumulator object
      acc[newKey] = newValue;
      return acc;
    },
    {} as Record<NK, NV>,
  );
}

export const longMonthFormatter = new Intl.DateTimeFormat("en-US", {
  month: "long",
});

export const getFirstPartOfSubdomain = (url: string): string | undefined | null => {
  try {
    const parts = url.split(".");
    if (parts.length >= 3) {
      return parts[parts.length - 3];
    } else {
      return null;
    }
  } catch (e) {
    console.error("Invalid URL:", e);
    return null;
  }
};

export function convertKeysToCamelCase<T extends Record<string, unknown>>(obj: T | undefined): T | undefined {
  if (!obj) return obj;
  return Object.keys(obj).reduce<T>((newObj, key) => {
    const newKey = key.replace(/(_\w)/g, (match) => (match[1] ? match[1].toUpperCase() : ""));
    return { ...newObj, [newKey]: obj[key] };
  }, {} as T);
}

export const getFirstTwoInitials = (fullName: string = ""): string => {
  return (
    fullName
      ?.split(" ")
      .filter((word) => word)
      .slice(0, 2)
      .map((word) => word[0]?.toUpperCase())
      .join("") || ""
  );
};

export const transformMessages = (data: OriginalFormat): TransformedFormat => {
  return {
    messages: data.messages.map((msg) => ({
      message: msg.message,
      from: msg.is_agent ? "system" : "User",
      message_id: msg.id,
      interaction_id: msg.interaction_id,
      format: msg.format,
    })),
  };
};
