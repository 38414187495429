"use client";

import { convertKeysToCamelCase, getFirstPartOfSubdomain } from "@repo/utils";
import { usePostHog } from "posthog-js/react";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { getOrgData } from "../services/orgService";
import type { StoreInterface, StoreType } from "./store";
import { Provider, initializeStore, store } from "./store";
export interface PreloadedStoreInterface extends Pick<StoreInterface, "domainData"> {}

interface StoreProviderProps extends PropsWithChildren<PreloadedStoreInterface> {
  host: string;
}

export default function StoreProvider({ children, host }: StoreProviderProps) {
  const [isStoreReady, setStoreReady] = useState(false);
  const storeRef = useRef<StoreType>(store);
  const posthog = usePostHog();

  useEffect(() => {
    let isMounted = true;

    const fetchAndInitializeStore = async () => {
      const storedData = localStorage.getItem("orgData");
      const parsedData = storedData ? JSON.parse(storedData) : null;

      let postHogOrg;

      if (!parsedData || parsedData.host !== host) {
        let orgId = getFirstPartOfSubdomain(host);
        if (orgId === "coachbot") {
          orgId = "newlevel";
        }

        if (orgId === "preview") {
          orgId = "dogfood";
        }

        orgId = host === "amigo.local.ai:3000" ? "dogfood" : orgId;

        postHogOrg = orgId;

        const response = await getOrgData(orgId as string, "Admin");
        const domainData = convertKeysToCamelCase(response as any);
        const newData = { host, domainData };
        localStorage.setItem("orgData", JSON.stringify(newData));

        // Initialize or reinitialize the store with the fetched data
        storeRef.current = initializeStore({
          domainData: newData.domainData,
        });
      } else {
        postHogOrg = parsedData.domainData.orgId;
        // Initialize the store with the stored data if the host matches
        storeRef.current = initializeStore({
          domainData: parsedData.domainData,
        });
      }

      // Identify organization with posthog
      if (postHogOrg) {
        posthog.group("organization", postHogOrg, {
          name: postHogOrg,
        });
      }

      if (isMounted) {
        setStoreReady(true);
      }
    };

    fetchAndInitializeStore();

    return () => {
      isMounted = false;
    };
  }, [host]);

  if (!isStoreReady || !storeRef.current) {
    return null; // Or a loading indicator if preferred
  }

  return <Provider value={storeRef.current}>{children}</Provider>;
}
